<template>
  <div class="askJoin">
    <div style="width: 100%;height: 90px;"></div>
    <div class="askJoinCenter">
      <div class="askTop">申请入驻</div>
      <div class="askForm">
        <div class="itemLabel">
          <div class="dian"></div>
          <span class="labelName">园所名称</span>
          <input type="text" v-model="dataForm.name">
        </div>
        <div class="itemLabel itemLabel22">
          <div class="dian"></div>
          <span class="labelName">所在地区</span>
          <el-cascader
              placeholder=""
              size="large"
              :options="options"
              v-model="selectedOptions"
              @change="handleChange">
          </el-cascader>
<!--          <input style="width: 128px;margin-right: 12px;" v-model="dataForm.area1" type="text">省<input style="width: 128px;margin-left: 12px;margin-right: 12px;" v-model="dataForm.area2" type="text">市<input style="width: 128px;margin-left: 12px;" v-model="dataForm.area3" type="text">-->
        </div>
        <div class="itemLabel">
          <div class="dian"></div>
          <span class="labelName">详细地址</span>
          <input type="text" v-model="dataForm.address">
        </div>
        <div class="itemLabel">
          <div class="dian"></div>
          <span class="labelName">联系人<span style="visibility:hidden;">名</span></span>
          <input v-model="dataForm.contactName" type="text">
        </div>
        <div class="itemLabel">
          <div class="dian"></div>
          <span class="labelName">联系电话</span>
          <input v-model="dataForm.contactMobile" type="text">
        </div>
        <div class="submit" @click="submit">提交</div>
      </div>

    </div>
  </div>
</template>
<script>
import axios from "axios";
import { regionData, CodeToText } from 'element-china-area-data'
export default {
  data(){
    return{
      options: regionData,
      selectedOptions: [],
      dataForm: {
        name: '',
        areaId: '',
        address: '',
        contactMobile: '',
        contactName: '',
        mobile: '',
        area1: '',
        area2: '',
        area3: '',
      }
    }
  },
  methods:{
    handleChange (value) {
      this.dataForm.area1 = CodeToText[value[0]]
      this.dataForm.area2 = CodeToText[value[1]]
      this.dataForm.area3 = CodeToText[value[2]]
    },
    submit(){
      if(this.dataForm.name === ''){
        this.$message.warning('园所名称不能为空');
        return;
      }
      if(this.dataForm.address === ''){
        this.$message.warning('详细地址不能为空');
        return;
      }
      if(this.dataForm.contactName === ''){
        this.$message.warning('联系人不能为空');
        return;
      }
      if(this.dataForm.area1 === ''){
        this.$message.warning('省不能为空');
        return;
      }
      if(this.dataForm.area2 === ''){
        this.$message.warning('市不能为空');
        return;
      }
      if(this.dataForm.area3 === ''){
        this.$message.warning('区不能为空');
        return;
      }
      if(!(/^((13[0-9])|(14[0-9])|(15([0-9]))|(16([0-9]))|(17([0-9]))|(18[0-9]))|(19([0-9]))\d{8}$/.test(this.dataForm.contactMobile))){
        this.$message.warning('请填写正确的11位手机号码');
        return;
      }
      this.dataForm.areaId = this.dataForm.area1 + this.dataForm.area2 + this.dataForm.area3;
      axios.post('http://gateway.yjbit.zhidiansaas.com:30191/schools/unAccess/door/nurseryRegister',this.dataForm).then(res=>{
        console.log(res)
        if(res.data.code === 0) {
          this.$message.success('提交成功');
          this.dataForm.name = ''
          this.dataForm.address = ''
          this.dataForm.contactName = ''
          this.dataForm.contactMobile = ''
          this.dataForm.area1 = ''
          this.dataForm.area2 = ''
          this.dataForm.area3 = ''
          this.dataForm.areaId = ''
          this.selectedOptions = []
        } else {
          this.$message.warning(res.data.msg);
        }
      })
    }
  }
}
</script>
<style lang="less">
.askJoin{
  // min-width: 1700px;
  width: 100%;
  height: 944px;
  //overflow: hidden;
  background:url('../assets/img/askJoin.png') no-repeat center center;;
  .askJoinCenter{
    background:#fff;
    margin: auto;
    width: 736px;
    height: 695px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    .askTop{
      width:100%;
      height: 88px;
      background: #FBD646;
      text-align: center;
      font-size: 36px;
      line-height: 88px;
    }
    .askForm{
      padding-top: 46px;
      .itemLabel22{
        input{
          width: 510px !important;
        }
      }
      .itemLabel{
        position: relative;
        padding-left: 62px;
        padding-bottom: 32px;
        width: 100%;
        .dian{
          position: absolute;
          left: 45px;
          top: 25px;
          width: 6px;
          height: 6px;
          background: #FBD646;
          border-radius: 50%;
        }
        .labelName{
          font-size: 18px;
          color: #111;
        }
        input{
          margin-left: 25px;
          width: 495px;
          height: 50px;
          background: #FFFFFF;
          border: 1px solid #EDEEF0;
          outline: none;
          padding-left: 10px;
          border-radius: 4px;
        }
      }
      .submit{
        cursor: pointer;
        width: 640px;
        height: 52px;
        background: #EBEDEC;
        text-align:center;
        line-height: 52px;
        font-size: 24px;
        color:#111;
        margin: auto;
      }
      .submit:hover{
        background: #FBD646;
      }
    }
  }
}
</style>
